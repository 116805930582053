.featured-slider {
    .carousel {
        cursor: pointer;
    }

    .carousel:active {
        cursor: grab;
    }

    /* Add fading effect at the edges */
    .display-container:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 60px;
        background-image: linear-gradient(to right, black, transparent);
        pointer-events: none;
        z-index: 1;
    }
    .display-container:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 60px;
        background-image: linear-gradient(to left, black, transparent);
        pointer-events: none;
    }

    .display-container {
        /* box-sizing: border-box; */
    }

    .slick-track {
        display: flex !important;
        /* column-gap: 20px; */
    }
    .slick-track:before,
    .slick-track:after {
        display: none !important;
    }
    .slick-prev::before,
    .slick-next::before {
        content: '' !important;
    }

    .slick-slide {
        width: auto !important;
        margin-right: 20px;
    }
    /* .slick-slide:first-of-type {
        margin-right: 0px;
    } */
    .slick-cloned[data-index='-1'] {
        margin-right: 0px;
    }
    /* .container > *:first-child {
        margin-left: 0;
      } */
    .left-arrow,
    .right-arrow {
        filter: drop-shadow(0px 3px 4px hsla(0, 0%, 0%, 0.25));
    }
}
