#topnav {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 4px solid #fff;
    .right {
        margin-left: auto;
        display: inherit;
        align-items: inherit;
        gap: 10px;
        select {
            border: 1px solid gray;
            border-radius: 5px;
            padding: 3px 10px;
        }
        .btn {
            border-color: gray;
            background-color: transparent;
            padding: 5px;
            display: inherit;
            svg {
                max-width: 20px;
                max-height: 20px;
                path {
                    stroke: gray;
                }
            }
        }
    }
    .left {
        display: inherit;
        align-items: inherit;
        height: inherit;
        .active-franchise {
            // margin-left: 1rem;
            font-size: 1.5rem;
            font-weight: 700;
        }
        .franchise-image-container {
            flex: 1 1 auto;
            height: 50px;
            margin-left: 10px;
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}

.calendar-container {
    table#calendar {
        // background-color: white;
        // margin: 1rem;

        // &.year-view {
        //     width: 1800px;
        //     td,
        //     th {
        //         width: 150px;
        //     }
        // }
        thead {
            tr {
                th:first-of-type {
                    // min-width: 200px;
                    // background: #fff;
                    // box-shadow: none !important;
                }
                th {
                    // text-align: left;
                    // width: 150px;
                    // box-shadow: inset 10px 0px 12px -13px rgb(0 0 0 / 50%);
                    &:not(:first-of-type) {
                        // border-right: 1px solid gray;
                        div {
                            // padding: 0.5rem;
                            // border-bottom-right-radius: 2px;
                            // border-bottom-left-radius: 2px;
                            // background: white;
                            // box-shadow: 0 5px 2px rgb(0 0 0 / 20%);
                        }
                    }
                }
            }
        }
        tbody {
            // border-bottom: 2px solid theme('colors.lightergray');

            .hide-header {
                th:first-of-type {
                    div {
                        color: transparent;
                        // background-color: white;
                        img {
                            display: none;
                        }
                    }
                    .icon-container {
                        display: none;
                    }
                }
            }
            .top-header {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            .bottom-header {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                margin-bottom: 4px;
            }

            th {
                &:first-of-type {
                    // border-right: 2px solid theme('colors.lightergray'); // background-color: #fff;
                    div {
                        position: relative;
                        // border-radius: 5px;
                        // background-color: gray;
                        // margin-bottom: 1rem;
                        // margin-right: 1rem;
                        // color: black;
                        // color: white;
                        display: flex;
                        align-items: center;
                        // justify-content: center;

                        img {
                            max-height: 25px;
                            // margin-left: auto;
                        }
                    }
                }
                &:nth-child(2) {
                    // box-shadow: inset 10px 0px 12px -13px rgb(0 0 0 / 50%);
                }
                // &:nth-child(n + 2) {
                //     border-right: 2px solid red;
                //     // border-bottom: 1px solid gray;
                //     text-align: center;
                // }
                &.announced-1 div {
                    // color: black;
                    color: red;
                }

                .popup {
                    display: none;
                }
                div {
                    // padding: 5px;
                    // margin: 5px;
                    // background-color: #fff;
                    // color: blue;
                    // background-color: red;
                    // p {
                    //     margin-top: 2px;
                    //     display: flex;
                    //     justify-content: center;
                    //     flex-wrap: wrap;
                    // }
                    .image-preview {
                        display: block;
                        margin: 0 auto;
                        height: 4rem;
                        max-width: 10rem;

                        // width: 100%;
                    }
                }
            }
            td[colspan='1'] {
                div {
                    .image-preview {
                        // width: 100%;
                        // height: 100%;
                        // max-height: 4rem;
                        // object-fit: contain;
                    }
                }
            }

            tr td {
                z-index: 0;
                position: relative;
                border-right: 2px dashed theme('colors.gray300');
                text-align: center;
                background: theme('colors.gray200');
                .hoverer {
                    display: none;
                }
                &:not(.empty):hover {
                    position: relative;
                    z-index: 100;
                    .hoverer {
                        display: block;
                        position: absolute;
                        top: 0px;
                        right: -100px;
                        width: 100px;
                    }
                }
            }
            tr td:last-of-type {
                border-right: 2px solid theme('colors.gray200');
            }
            tr:last-of-type td {
                border-bottom: 2px solid theme('colors.gray200');
            }
            td::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                border-top: 1px solid theme('colors.gray300');
                transform: translateY(-50%);
                z-index: -1;
            }
        }
        .indent {
            padding-left: 30px !important;
            text-align: right;
        }
    }
    .caret-icon path {
        fill: black;
    }
}
