body {
    background-color: black;
}
.franchise-hub {
    position: relative;
    overflow-x: hidden;

    .intro-text {
        p {
            padding-bottom: 1rem;
        }
    }
    .franchise-gradient {
        background: radial-gradient(
            circle,
            rgba(0, 0, 0, 0.25) 0%,
            rgba(0, 0, 0, 0.7) 75%,
            rgba(0, 0, 0, 1) 100%
        );
        padding: 15rem 1.5rem 3rem;
        min-height: 100vh;
        z-index: 2;
        position: relative;
    }

    .hero-title {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .welcome-section-bg {
        clip-path: polygon(
            -30% 100%,
            /* left top */ 15% 0%,
            /* right top */ 100% 0%,
            /* right bottom */ 60% 100% /* left bottom */
        );
    }

    .caret-icon-right path {
        fill: #0074e8;
        font-size: 10px;
    }
    // .gradient-container {
    //     // border-radius: 5px;
    //     background: linear-gradient(
    //                 to bottom right,
    //                 rgba(78, 38, 191, 0.55) 0%,
    //                 #080918 80%
    //             )
    //             bottom right / 50% 50% no-repeat,
    //         linear-gradient(
    //                 to bottom left,
    //                 rgba(78, 38, 191, 0.55) 0%,
    //                 #080918 80%
    //             )
    //             bottom left / 50% 50% no-repeat,
    //         linear-gradient(
    //                 to top left,
    //                 rgba(78, 38, 191, 0.55) 0%,
    //                 #080918 80%
    //             )
    //             top left / 50% 50% no-repeat,
    //         linear-gradient(
    //                 to top right,
    //                 rgba(78, 38, 191, 0.55) 0%,
    //                 #080918 80%
    //             )
    //             top right / 50% 50% no-repeat;
    // }
    .gradient-container {
        background: -webkit-radial-gradient(
            at 50% 65%,
            hsla(256, 67%, 45%, 0.55) -10%,
            #080918 60%
        );
        background: radial-gradient(
            at 50% 65%,
            hsla(256, 67%, 45%, 0.55) -10%,
            #080918 60%
        );
    }
}
