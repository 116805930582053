/* styles.css or your preferred stylesheet */
.triangle-up {
    z-index: 50;
    position: absolute;
    top: -7px; /* Adjust this value to control the distance between the triangle and the button */
    left: 3px; /* Adjust this value to control the horizontal position of the triangle */
    width: 0px;
    height: 0px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 10px solid white; /* Adjust this value to control the size of the triangle */
    border-top: 0;
}
