.collection-container::before,
.collection-container-homepage::before {
    content: '';
    position: relative;
    overflow: hidden;
    padding: 10px;
    display: block;
    position: absolute;
    inset: 0;
    /* background-color: rgba(79, 38, 192, 0.55); */
    /* background-color: #261661; */
    mix-blend-mode: color-dodge;
    border-radius: 5px;
}
.collection-container-homepage::before {
    background-color: #261661;
}
.collection-container {
    background-color: rgba(0, 0, 0, 0.4);
}
.image-container {
    border-radius: 5px;
    position: relative;
    /* background: none; */
}
