$slate: #121826;

.search-container {
    // background-color: white;
    position: relative;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;

    input {
        color: black;
        border-radius: 5px;
        border: 0;
        outline: none;
    }
    .matches:not(:empty) {
        position: relative;
        z-index: 10;
        background-color: white;
        max-height: 400px;
        padding: 0.2rem 1rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        a {
            color: $slate;
        }
    }
}
