.article-page {
    // Guidelines Section
    .caret-icon-right path {
        fill: #0074e8;
        font-size: 10px;
    }

    li.bullets {
        a:before {
            content: '•'; //the bullet
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            left: 0.5rem;
            // padding-right: 2rem;
        }
        a {
            border-bottom: 1px solid white;
            color: var(--color-blue);
        }
    }

    // Specific to article page wordpress content only
    .wp-content {
        font-size: 1rem;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: var(--color-midblue);
        }
        p {
            font-size: 1rem;
        }
        ul {
            list-style-type: disc;
            padding-inline-start: 2.5rem;
            margin: 0.75rem 0;
            ul {
                list-style-type: circle;
                margin: 0;
            }
        }
        ol {
            padding-inline-start: 2.5rem;
            margin: 0.75rem 0;
            ol {
                margin: 0;
            }
        }
        figure {
            margin: 0 0.75rem;
        }
        th img,
        td img {
            margin: 0 auto 0.75rem;
        }

        ol,
        ul,
        menu {
            list-style: revert;
            list-style-position: outside;
        }
        a {
            color: var(--color-blue);
            text-decoration: none;
        }
    }
    // Wordpress Content
    .wp-block-columns {
        display: flex;
        justify-content: center;
        column-gap: 2rem;
    }

    .wp-block-column {
        flex: 1;
        img {
            margin: 0 auto;
        }
    }
    .wp-block-table {
        width: 100%;
        table {
            width: 100%;
            margin-bottom: 2rem;
        }

        th {
            font-weight: 700;
            font-size: 1.75rem;
            line-height: 2.25rem;
            text-align: left;
            border-bottom: 1px solid var(--color-lightslate);
            padding: 1rem 0.75rem;
            min-width: 170px;
        }
        td {
            padding: 2rem 0.5rem;
            font-size: 1.625rem;
            line-height: 2.25rem;
            vertical-align: top;
        }
        tr {
            border-bottom: 1px solid var(--color-lightslate);
        }
        img {
            margin-bottom: 10px;
        }
    }

    .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
        background-color: #f0f0f0;
    }
}
