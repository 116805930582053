.homepage {
    --bg-darkblue: #010a2b;
    main {
        color: #ffffff;
    }
    h1 span {
        display: block;
    }

    main figure caption {
        font-size: 1rem;
    }
    main .features {
        display: flex;
        flex-flow: wrap;
        margin: 0 auto;
        max-width: 1000px;
        justify-content: center;
        flex-wrap: wrap;
    }

    main .features figure {
        width: 210px;
        & > div {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        figcaption {
            p {
                margin-top: 0 !important;
            }
        }
    }

    main .features figure h5 {
        margin-bottom: 0;
    }
    main .features svg {
        width: 95px;
        margin: 0.75rem 0;
    }
    main h4 {
        max-width: 750px;
        margin: 0px auto;
    }

    @media screen and (max-width: 768px) {
        h2 {
            font-size: 5rem;
        }
    }
}
