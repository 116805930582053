$topbarheight: 50px;
$navwidth: 320px;
$navsubitempadding: 0.75rem;
$navitempadding: 1.125rem;
$blue: #0074e8;
$darkblue: #0a1747;
$lightblue: #7faad9;
$slate: #121826;
$lightslate: #3a4362;
$navfontsize: 14px;

.franchise-hub-globalnav::webkit-scrollbar {
    display: none;
}
.franchise-hub-globalnav {
    z-index: 999;
    font-size: $navfontsize;
    display: flex;
    flex-direction: column;
    .back-to-home {
        height: 20px;
        line-height: 20px;
        text-transform: uppercase;
        // margin-bottom: 20px !important;
        a {
            color: $lightblue;
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        svg {
            transform: rotate(-180deg);
            margin-right: 1rem;
            path {
                fill: $lightblue;
            }
        }
    }
    .nav-header {
        height: $topbarheight;
        line-height: $topbarheight;
        & > * {
            flex-grow: 1;
            flex-basis: 0;
            &:nth-child(2) {
                text-align: center;
                display: flex;
                flex: 0 0 200px;
                align-items: center;
                justify-content: center;

                span {
                    text-transform: uppercase;
                }
                img,
                svg {
                    max-width: 24px;
                    margin-left: -0.75rem;
                    margin-right: 0.75rem;
                }
            }
            &:last-child {
                text-align: right;
            }
        }
    }
    .franchise-hub-nav {
        & > ul {
            margin: 0.875rem 0;
            float: left;

            a {
                text-decoration: none;
                // text-transform: uppercase;
                width: calc(100% - 25px);
                // border-right: 1px solid white;
                transition: 250ms linear color;
                &:hover,
                &.active {
                    color: white !important;
                }
            }
            ul {
                display: none;
                li {
                    // height: $navsubitemheight;
                    // line-height: 40px;
                    // padding: $navsubitempadding;
                    margin: 0 18px;
                    border-bottom: 1px solid #ffffff;
                    padding: 4px 0;
                }
            }
            li {
                // height: $navitemheight;
                padding: 0.75rem 1.25rem;
                div {
                    display: flex;

                    .drill-in {
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        ul.level-active {
            display: block;
        }
        .content-type {
            display: flex;
            align-items: center;

            span {
                // border-right: 1px solid white;
                // width: calc(100% - 25px);
            }
            svg {
                margin-left: auto;
                transform: rotate(90deg);
            }

            &.selected {
                background-color: $blue;
                & + ul {
                    display: block;
                    // list-style-type: none;
                    // background-color: $darkblue;
                    a {
                        // border-right: 0 !important;
                        color: white;
                    }
                }
                svg {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}

.nav-container {
    overflow-x: hidden;
    flex: 1 1 0;
}

@media (max-width: 639px) {
    $navwidth: 99vw;
    .franchise-hub-nav {
        transition: 250ms ease-in all;
        width: calc($navwidth * 3);
        ul {
            width: $navwidth;
        }
        &.level-3 {
            transform: translate(-$navwidth * 2, 0);
        }
        &.level-2 {
            transform: translate(-$navwidth, 0);
        }
        .level-2 ul {
            // list-style-type: disc;
            // list-style-position: outside;
            // padding-inline-start: 40px;
        }
    }
}
