.homepage {
    header nav {
        z-index: 2;
    }

    @media screen and (max-width: 768px) {
        header {
            padding: 2rem 4rem !important;
        }
    }
}

.standard-page {
    header {
        nav {
            grid-template-columns: 150px calc(100% - 150px);
            position: relative;
            z-index: 9;
            a {
                color: #ffffff;
                font-weight: bold;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                text-decoration: none;
                font-family: WarnerBrosSansCond;
            }
        }
        .logo {
            svg {
                width: 120px;
            }
        }
    }
}
