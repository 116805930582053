@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&display=swap');

:root {
    --color-slate: #121826;
    --color-lightslate: #3a4362;
    --color-lightgrey: #e5e7eb;
    --color-lightblue: #7faad9;
    --color-darkblue: #0a1747;
    --color-blue: #0074e8;
    --color-midblue: #04198c;
    --color-wbyellow: #ffd027;
    --color-wbslate: #121826;
    --bodyFont: WarnerBrosSans, sans-serif;
    --borderRadius: 0.417rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out all;
    --navHeight: 80px;
    --footerHeight: 96px;
    --franchiseNavHeight: 97px;
    --franchiseFooterHeight: 128px;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: WarnerBrosSans;
    position: relative;
    height: 100%;
    min-height: 100vh;
}
#root {
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
}
html,
body,
#root {
    min-height: 100vh;
}

main {
    display: block;
    flex: 1;
}

b,
strong {
    font-weight: bolder;
}
img {
    border-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: WarnerBrosSansCond;
    -webkit-font-smoothing: antialiased;
}
h1 {
    font-size: 3.052rem;
    text-transform: uppercase;
}

h2 {
    font-size: 2.441rem;
    text-transform: uppercase;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}
h6 {
    font-size: 1rem;
}

dl dd {
    display: inline;
    margin: 0;
}
dl dd:after {
    display: block;
    content: '';
}
dl dt {
    display: inline-block;
    margin-right: 0.5rem;
    text-transform: uppercase;
    color: grey;
}
fieldset {
    border: 0;
}
input,
select,
textarea {
    width: 100%;
}

.standard-page {
    background-color: #010a2b;
    position: relative;
    min-height: 100vh;
    color: white;
}

.standard-page:before {
    content: ' ';
    display: table;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 5, 25, 0),
        #000519 47%,
        #000519 76%,
        rgba(0, 5, 25, 0)
    );
}

.btn {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border-radius: 0.6em;
    cursor: pointer;
    display: flex;
    align-self: center;
    line-height: 1;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    width: auto;
    // padding: 1rem 3rem !important;
    &:hover,
    &:focus {
        color: #fff;
        outline: 0;
    }
}

.btn-style {
    border-color: #fff;
    color: #fff;
    background: {
        image: linear-gradient(45deg, #fff 50%, transparent 50%);
        position: 100%;
        size: 400%;
    }
    transition: all 300ms ease-in-out;

    &:hover {
        background-position: 0;
        color: #000;
    }
}
.standard-page {
    main {
        position: relative;
        z-index: 9;
    }
    header {
        &:after {
            display: table;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: radial-gradient(
                circle at 50% 50%,
                rgba(0, 5, 25, 0),
                #000519 101%
            );
        }
    }
}

$grid-base: 12;
$one-col-width: 8.333333333333333%;

/*=====general purpose utility classes==*/

.flex,
.flex-center-x,
.flex-center-y,
.flex-center-xy {
    display: flex;
}

/* Align item in center wrt major axis*/

.flex-center-x {
    justify-content: center;
}

/* Align item in center wrt minor axis*/

.flex-center-y {
    align-items: center;
}

/* Align item in center wrt major and minor axis*/

.flex-center-xy {
    @extend .flex-center-x;
    @extend .flex-center-y;
    height: 100%;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

/*----------Grid------------*/

.container-fluid {
    width: 100%;
}

.container-fluid {
    margin: 0 auto;
}

.pointer {
    cursor: pointer;
}

// @media (min-width: 768px) {
//   .container {
//     width: 750px;
//   }
// }

// @media (min-width: 992px) {
//   .container {
//     width: 970px;
//
// }

// @media (min-width: 1200px) {
//   .container {
//     width: 1200px;
//   }
// }

@media (min-width: 1400px) {
    .container,
    .container-fluid {
        width: 1400px;
    }
}

.row {
    @extend .flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.row > * {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

@for $i from 1 through $grid-base {
    .col-xs-#{$i} {
        width: $one-col-width * $i;
    }
}

/* col-sm-* classes */

@media (min-width: 768px) {
    @for $i from 1 through $grid-base {
        .col-sm-#{$i} {
            width: $one-col-width * $i;
        }
    }
}

/* col-md-* classes*/

@media (min-width: 992px) {
    @for $i from 1 through $grid-base {
        .col-md-#{$i} {
            width: $one-col-width * $i;
        }
    }
}

/* col-lg-* classes*/

@media (min-width: 1200px) {
    @for $i from 1 through $grid-base {
        .col-lg-#{$i} {
            width: $one-col-width * $i;
        }
    }
}
// utility class for calculated height minus nav and footer
.h-page {
    height: calc(100vh - var(--navHeight) - var(--footerHeight));
}
.min-h-page {
    min-height: calc(100vh - var(--navHeight) - var(--footerHeight));
}
.max-h-page {
    max-height: calc(100vh - var(--navHeight) - var(--footerHeight));
}
.min-h-hubPage {
    min-height: calc(100vh - var(--footerHeight));
}
.min-h-franchise-article {
    min-height: calc(100vh - 241px - 128px); // nav height + h1
}
.min-h-calendar-article {
    min-height: calc(100vh - var(--navHeight) - var(--footerHeight) - 270px);
}
.min-h-franchise-page {
    min-height: calc(100vh - var(--franchiseFooterHeight));
}
// Hide the scrollbar but still be able to scroll.
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.wp-content {
    font-size: 1.75rem;
    font-family: 'WarnerBrosSans';

    p {
        font-size: 1.75rem;
        font-family: 'WarnerBrosSans';
        margin-bottom: 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: uppercase;
        margin: 3rem 0 1.38rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 800;
        line-height: 1.3;
    }

    h1 {
        margin-top: 0;
    }

    hr {
        margin: 0.5rem 0;
    }

    figure,
    pre,
    blockquote {
        margin: revert;
    }

    ul {
        li a:hover {
            opacity: 80%;
        }
    }
}
