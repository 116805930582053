.homepage {
    /* Carousel */
    .carousel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    ul.slides {
        display: block;
        position: relative;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        list-style: none;
    }
    .slides * {
        user-select: none;
        -ms-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
    }
    ul.slides input {
        display: none;
    }
    .slide-container {
        display: block;
    }
    .slide-image {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
        transition: all 0.7s ease-in-out;
    }
    .slide-image img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        object-position: top;
    }
    .carousel-controls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        font-size: 100px;
        color: #fff;
    }
    .carousel-controls > div {
        position: absolute;
        opacity: 0.5;
        transition: opacity 0.2s;
        cursor: pointer;
    }
    .carousel-controls {
        top: calc(50% - 50px);
    }
    .carousel-controls > div:hover {
        opacity: 1;
    }
    .carousel-controls .prev-slide {
        left: 0;
    }
    .carousel-controls .next-slide {
        right: 0;
    }
    .slide-container.active-slide .slide-image {
        opacity: 1;
        transform: scale(1);
        transition: opacity 1s ease-in-out;
    }

    .fully-contain img {
        display: block;
        margin: 0 auto;
        height: 500px;
        width: auto;
        object-fit: initial;
    }

    header aside {
        z-index: 2;
    }

    @media screen and (max-width: 768px) {
        .homepage {
            aside {
                max-width: 80% !important;
            }
        }
        h1 {
            font-size: 5rem;
        }
    }
}
