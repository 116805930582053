.brand-page {
    .wp-content {
        ol {
            list-style-type: revert;
            margin: revert;
            padding: 0;
            ol {
                padding: revert;
            }
        }
        p {
            text-align: justify;
            text-align-last: left;
        }
        ul {
            padding: 0;
            margin: 1rem auto;
            max-width: fit-content;
            text-transform: uppercase;

            // li:not(:has(a)):before {
            //     content: '\003E';
            //     display: inline-block;
            //     width: 1rem;
            //     height: 1.125rem;
            //     padding-right: 2rem;
            //     color: inherit;
            // }
            // li:has(a):before {
            //     content: '\003E';
            //     display: inline-block;
            //     width: 1rem;
            //     height: 1.125rem;
            //     padding-right: 2rem;
            // }
            li {
                // margin: 0.75rem 0;
                // font-weight: 800;
                // letter-spacing: 1px;
                // line-height: 35px;
                // font-size: 1.625rem;
                border-bottom: 1px solid #999999;
                // color: inherit;
            }
            li:has(ul) {
                border-bottom: none;
            }
            ul {
                margin: 0 auto;
                padding: revert;
            }
        }
        ol,
        ul {
            li {
                // font-family: 'Open Sans';
                // font-weight: 800;
            }
        }
    }
    .blur-container {
        background: rgba(0, 0, 0, 0.5);

        backdrop-filter: blur(20px);
    }
}
