$slate: #121826;

.animatedsearch-container {
    position: relative;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;

    svg {
        right: 0;
    }
    input {
        background: transparent;
        outline: none !important;
    }
    .matches:not(:empty) {
        background-color: white;
        max-height: 400px;
        overflow-y: scroll;
        padding: 0.2rem 1rem;
    }
    .search-icon {
        transition: all 0.2s linear;
    }

    .search-icon path {
        fill: #ffffff;
    }
    .matches {
        display: none;
    }

    .magnifying-glass {
        transition: all 0.2s linear;
    }

    .search-input {
        transition: all 0.2s linear;
    }
}

.nav:hover .animatedsearch-container .matches {
    display: block;
}

.nav:hover .animatedsearch-container .magnifying-glass {
    fill: black;
}
