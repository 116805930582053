.franchise-topbarv2 {
    .internal-icon,
    .external-icon {
        height: 32px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: all 0.2s linear;
        border-radius: 5px;
    }

    .nav {
        transition: all 0.2s linear;
    }

    .nav:hover .hamburger-nav {
        stroke: black;
    }
}
