div.homepage:before {
    content: ' ';
    display: table;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 5, 25, 0),
        #000519 47%,
        #000519 76%,
        rgba(0, 5, 25, 0)
    );
}
.homepage {
    @media screen and (max-width: 768px) {
        #contact .contact-us {
            transform: translate(-50%, 0);
            width: 80%;
        }
    }
    @media screen and (max-width: 479px) {
        main #contact .contact-us {
            width: 90%;
        }
    }
}
