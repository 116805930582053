div.support {
    background-color: #010a2b;
    &:before {
        content: ' ';
        display: table;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 5, 25, 0),
            #000519 47%,
            #000519 76%,
            rgba(0, 5, 25, 0)
        );
    }
    main {
        z-index: 4;
        position: relative;
        padding: 2.25rem 0;
        aside {
            text-align: center;
        }
        form {
            margin: 0px auto;
            label {
                font-size: 1;
            }
            .helptext {
                display: block;
                margin-bottom: 0.75rem;
            }
            input:not([type='submit']),
            label {
                display: block;
            }
            label {
                margin-bottom: 10px;
                font-weight: 700;
            }
        }
    }
}
