.brand-hero {
    .visually-hidden {
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%); /* browsers in the future */
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
    .wp-brand-hero {
        // color: white;
        // overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        font-weight: 700;
        /* Hide scrollbar for Chrome, Safari and Opera */
    }
    .wp-brand-hero::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .wp-brand-hero {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .text-shadow {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
