.default-styles {
    h1 {
        margin: 0.54em 0;
    } /* 80% of 0.67em */
    h2 {
        margin: 0.66em 0;
    } /* 80% of 0.83em */
    h3 {
        margin: 0.8em 0;
    } /* 80% of 1em */
    h4 {
        margin: 1.06em 0;
    } /* 80% of 1.33em */
    h5 {
        margin: 1.34em 0;
    } /* 80% of 1.67em */
    h6 {
        margin: 1.86em 0;
    } /* 80% of 2.33em */

    p {
        margin: 0.8em 0;
    } /* 80% of 1em */
}
