/* ActiveGridPage.scss */

.masonry {
    --masonry-gap: 1rem;
    --masonry-brick-width: 368px;
    column-gap: var(--masonry-gap);
    column-fill: initial;
    column-width: var(--masonry-brick-width);
}

.masonry > * {
    break-inside: avoid;
    margin-bottom: var(--masonry-gap);
}

@supports (grid-template-rows: masonry) {
    .masonry {
        display: grid;
        gap: var(--masonry-gap);
        grid-template-rows: masonry;
        grid-template-columns: repeat(
            3,
            minmax(var(--masonry-brick-width), 300px)
        );
        align-tracks: stretch;
    }

    .masonry > * {
        margin-bottom: initial;
    }
}

.masonry {
    // background: #f3f3f3;
    // padding: 2rem;
}

.brick {
    color: white;
    width: 368px;
    // max-width: 100%;
    // padding: 1rem;
}
/* larger */
@media (min-width: 1024px) {
    .masonry {
        column-count: 3;
    }
}

/* medium */
@media (max-width: 1023px) and (min-width: 768px) {
    .masonry {
        column-count: 3;
    }
}

/* small, anything smaller will be 1 column by default */
@media (max-width: 767px) and (min-width: 540px) {
    .masonry {
        column-count: 2;
    }
}
